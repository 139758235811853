<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <!-- <TopNavigation step="0" :title="'Payment'"/> -->
    <TopNavigation step="0" :title="route.params.result === 'success' ? 'Payment Successful' : 'Payment Failed'"/>

    <div class="generalBorderlessBox">
        <div class="row">
            <div v-if="route.params.result === 'success'" class="col-12 text-center mb-4">
                <div class="mb-2">Thank you for your purchase.</div>
            </div>

            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <div v-if="payment" class="generalContentBox border shadow-sm mb-4">
                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                <td class="fw-bold">Transaction ID</td>
                                <td>{{ payment.paymentId }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Transaction Date</td>
                                <td>{{func.convDateTimeFormat(payment.dateTransaction, 'datetime')}}</td>
                            </tr>
                            
                            <tr v-if="payment.jUser">
                                <td class="fw-bold">Payee</td>
                                <td>
                                    <span v-if="payment.jUser.fullName !== '' && payment.jUser.fullName !== undefined && payment.jUser.displayName !== '' && payment.jUser.displayName !== undefined">
                                        {{ payment.jUser.fullName }} <span class="text-grey"><i class="fa-regular fa-at mx-1"></i>{{payment.jUser.displayName}}</span>
                                    </span>
                                    <span v-else-if="payment.jUser.fullName !== '' && payment.jUser.fullName !== undefined">
                                        {{ payment.jUser.fullName }}
                                    </span>
                                    <span v-else-if="payment.jUser.displayName !== '' && payment.jUser.displayName !== undefined">
                                        {{payment.jUser.displayName}}
                                    </span>
                                    <span v-else>-</span>
                                </td>
                            </tr>
                            <tr v-if="payment.jUser">
                                <td class="fw-bold">Payee Email</td>
                                <td>{{ payment.jUser.userId }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Amount</td>
                                <td>{{ payment.currency }} {{ payment.amount.toLocaleString() }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Status</td>
                                <td v-if="payment.result === 'SUCCESS'" class="fw-bold text-success">{{ payment.result }}</td>
                                <td v-else-if="PENDING" class="fw-bold text-info">{{ payment.result }}</td>
                                <td v-else class="fw-bold text-danger">{{ payment.result }}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Payment Method</td>
                                <td>{{payment.vendor}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- <div>
                    <button v-if="isSignOn" class="btn btn-warning">
                        <i class="fa-solid fa-download me-2"></i>Download Invoice
                    </button>
                    <button class="btn btn-secondary float-end" @click="$router.push({ path: '/plan' })">
                        <i class="fa-solid fa-box-check me-2"></i>View Plan
                    </button>
                </div> -->

                <div>
                    <button class="btn btn-outline-secondary me-2" @click="$router.push({ path: '/dashboard' })">
                        <i class="fa-solid fa-gauge me-2"></i>Dashboard
                    </button>
                    <button class="btn btn-outline-secondary me-2" @click="$router.push({ path: '/auth/preference', query: {ct: 'bill'} })">
                        <i class="fa-solid fa-envelope-open-dollar me-2"></i>Billing
                    </button>

                    <button v-if="payment" class="btn btn-secondary float-end" @click="downloadInvoice(payment.invoiceId)">
                        <i class="fa-solid fa-download me-2"></i>Download Invoice
                    </button>
                </div>
            </div>
            <div class="col-lg-2"></div>

            
        </div>
        
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'


export default {
    name: 'PaymentSuccess',
    components: { TopNavigation, Alert },
    setup () {

        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const isSignOn = ref(false)
        const paymentId = ref('')
        const payment = ref(null)

        const downloadInvoice = async (invoiceId) => {
            const url = axios.defaults.baseURL + 'invoice/download/' + invoiceId
            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = 'invoice_'+invoiceId

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const getPaymentInfo = () => {
            axios.get('/payment/' + paymentId.value)
                .then((res) => {
                    
                    if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        console.info('getPaymentInfo', JSON.stringify(res.data.data))
                        payment.value = res.data.data

                    } else {
                        func.addLog('paymentResult', 'getPaymentInfo', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }
                    }

                })
                .catch((error) => {
                    func.addLog('paymentResult', 'getPaymentInfo - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

            /* payment.value = {
                jUser:{
                    entityId: "AF3D23CDD7E011EF973A02E83A880312",
                    userId: "yn3@testing.com",
                    fullName: "",
                    displayName: "Yn Three",
                    isVerified:false,
                    jAvatar:{}
                },
                paymentId:"dWiza12jqr75C6aUG6Iams",
                invoiceId:"eyWmtGZqJjfrdG5ndeXruy5nZnbmdjfodnbodGWmZeY",
                currency:"USD",
                amount:"18.00",
                vendor:"STRIPE",
                dateTransaction:"2025-01-22T07:46:06.000Z",
                result:"SUCCESS"
            } */
        }

        const resetSession = async () => {
            try {
                const res = await axios.get('/auth/session/reset')

                if( res.data.status === 1) {
                    // console.info('topnav sess', JSON.stringify(res.data.data.jEntity))
                } 

            } catch (error) {
                func.addLog('paymentResult', 'resetSession - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
            }
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        onMounted(async () => {
            resetSession()

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            paymentId.value = route.query.id
            if (paymentId.value !== '' && paymentId.value !== null) {
                getPaymentInfo()
                
            }
        })

        return {
            route, router, alert, func, closeAlert, isSignOn, payment, paymentId, downloadInvoice
        }
    }

}
</script>
<style>
    
</style>